import { Controller } from "@hotwired/stimulus"
import { createPopup } from "@picmo/popup-picker";

export default class extends Controller {
  static targets = [ "button", "input" ]

  connect() {
    const trigger = document.querySelector('.emoji-button');
    const rootElement = document.querySelector('.emoji-picker');
    const target = document.querySelector('.emoji-container')

    this.picker = createPopup({
      animate: true,
      rootElement: rootElement,
      }, {
        referenceElement: trigger,
        triggerElement: trigger
      });

    this.picker.addEventListener('emoji:select', selection => {
      target.innerHTML = selection.emoji;
      this.inputTarget.value = selection.emoji;
    })
  }

  clear(event) {
    event.preventDefault()
    this.inputTarget.value = '';
    this.element.querySelector('.emoji-container').innerHTML = '';
  }

  toggle(event) {
    event.preventDefault()
    this.picker.toggle(event.trigger);
  }
}
