import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pasteArea"]

  copyPaste() {
    try {
      this.pasteAreaTarget.value = (this.pasteAreaTarget.value + " " + event.params['body'])
    } catch (e) {
      return
    }
  }
}