import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "slackIntegrationButton"];

    toggle() {
      if (this.sidebarContainerTarget.dataset.expanded === "1") {
        this.collapse()
      } else {
        this.expand()
      }
    }

    collapse() {
      this.sidebarContainerTarget.classList.toggle("hidden");
      this.sidebarContainerTarget.dataset.expanded = "0"
    }

    expand() {
      this.sidebarContainerTarget.dataset.expanded = "1"
      this.sidebarContainerTarget.classList.toggle("hidden");
    }
}