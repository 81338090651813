import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  change() {
    // const arrowDown = document.querySelectorAll("#sort-name-down");
    // const arrowUp = document.querySelectorAll("#sort-name-up");

    const element = document.querySelectorAll("#name-arrow");

    const arrowDown = "<i class='fas fa-caret-down me-1' id='sort-name-down'></i>"
    const arrowUp = "<i class='fas fa-caret-up me-1' id='sort-name-up'></i>"

    const arrowIcon = element[0].children[0]

    if (element[0].children) {
      const fragment = document
        .createRange()
        .createContextualFragment(arrowUp);

      element[0].appendChild(fragment)
    } else if (arrowIcon == arrowDown) {
      const fragment = document
        .createRange()
        .createContextualFragment(arrowUp);

      element[0].appendChild(fragment)
    } else {
      const fragment = document
        .createRange()
        .createContextualFragment(arrowDown);

      element[0].appendChild(fragment)
    }
  }
}